<template>
  <div class="update-fields">
    <div class="row">
      <VasionDropList
        v-slot="slotItem"
        v-model="attributeForm"
        :dataArray="attributeFormList"
        class="fullWidth"
        :width="'100%'"
        :title="`${$formsLabel.toUpperCase()}`"
        placeholder="..."
        type="plain-list"
        displayName="name"
        valueName="value"
        @input="isDirty = true"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <div class="row">
      <hr>
    </div>
    <div class="row">
      <div class="index-field-list-padding">
        <IndexFieldList
          ref="attributeFieldsComponent"
          :fields="indexFields"
          :enforceFieldSecurity="true"
          :selectorOnly="false"
          :showSubmit="false"
          :fieldWidth="225"
          :useMultiSelectLists="true"
          @fieldValueUpdated="updateLocalFieldValues"
        />
      </div>
    </div>
    <div class="row flex-center footer">
      <VasionCheckbox
        id="re-run-workflow-step"
        class="alignLeft"
        name="re-run-workflow-step"
        :checked="reRunWorkflowStep"
        @change="changeCheckboxValue"
      >
        Re-run Workflow Step Entry Logic
      </VasionCheckbox>
      <div class="alignRight">
        <VasionButton id="reject-button" :class="'secondary'" @vasionButtonClicked="cancel()">
          Cancel
        </VasionButton>
        <VasionButton id="confirm-button" :classProp="'primary'" @vasionButtonClicked="updateFields()">
          Update Fields
        </VasionButton>
      </div>
    </div>
  </div>
</template>

<script>
import IndexFieldList from '@/components/IndexFieldList.vue';

export default {
  name: 'UpdateFields',
  components: {
    IndexFieldList,
  },
  props: {
    selectedFormID: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      attributeForm: '',
      indexFields: [],
      indexFilters: [],
      localFieldValues: [],
      objectField: '',
      reRunWorkflowStep: true,
    }
  },
  computed: {
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
  },
  watch: {
    attributeForm: async function () {
      this.indexFields = await this.$store.dispatch('common/getIndexFieldsForForm', this.attributeForm.value)
      this.stringComparisonField = {}
    },
    indexFields: function () {
      if (this.indexFields) {
        this.indexFields.map((field) => {
          const foundField = this.documentAttributeFields.find((f) => {
            return f.FieldName === field.value
          })

          field.filterValue = this.getValueFromDocField(foundField)
          field.readOnly = field?.filterValue === '' || field?.filterValue === null ? false : field.readOnly
          return true
        })
      }
      this.localFieldValues = []
    },
  },
  async created() {
    await this.$store.dispatch('attributeForm/getForms')
    this.attributeForm = this.attributeFormList.find(f => f.value === this.selectedFormID)
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    changeCheckboxValue() {
      this.reRunWorkflowStep = !this.reRunWorkflowStep
      this.$emit('checkboxChanged', this.reRunWorkflowStep)
    },
    updateFields() {
      this.$emit('updateFields')
    },
    updateLocalFieldValues(childComponentValues) {
      this.localFieldValues = childComponentValues
      this.$emit('fieldValueUpdated', this.indexFilters)
    },
  },
}
</script>

<style>

.update-fields{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 900px;
    height: 700px;
  }
  .row {
    width: 100%;
    margin: 5px 0px;
  }
  .flex-center {
    display: flex;
    justify-content: center;
  }
  .fullWidth {
    width: 100%;
  }
  .footer {
    background-color: white;
    z-index: 999;
    position: sticky;
    bottom: 0;
    right: 0;
  }
  .alignRight {
    width: 50%;
    text-align: right;
  }
  .alignLeft {
    width: 50%;
    text-align: left;
    display: flex;
    align-items: center;
  }

</style>
