<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <div v-if="apiToken">
        <h1 v-if="!isDataRecentDocuments && !isDataDrafts">{{ headerText }}</h1>
        <h1 v-else-if="isDataDrafts">Drafts</h1>
        <h1 v-else>Recent Documents</h1>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="!isDataDrafts" class="table-nav">
        <div v-if="!isDataRecentDocuments">
          <VasionButton
            id="home-inbox-actions-button"
            :isDisabled="!areThereCheckedDocuments"
            :classProp="'secondary'"
            @vasionButtonClicked="actionsClick"
          >
            Actions
          </VasionButton>
          <VasionActionDropDown
            v-if="showActions"
            :actionsConfig="actionsConfig"
            :areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments="areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments"
            :areThereCertifiedDocuments="areThereCertifiedDocuments"
            :workflowActionsActive="workflowActionsActive"
            @approve="toggleGeneralModal($event, approveDocuments)"
            @download="downloadSelectedDocuments"
            @email="emailSelectedDocuments"
            @loseFocus="actionsClick"
            @print="toggleGeneralModal($event, printSelectedDocuments)"
            @reassign="toggleGeneralModal($event, reassignWorkflow)"
            @reject="toggleGeneralModal($event, rejectDocuments)"
            @terminate="toggleGeneralModal($event, terminateSelectedDocuments)"
            @takeOwnership="toggleGeneralModal($event, takeOwnershipDocuments)"
            @thumbnailManager="showThumbnailManager = true"
            @goToCompareDocuments="goToCompareDocuments"
            @updateFields="updateFieldsSelectedDocuments"
            @openNewTab="openNewTab"
          />
        </div>
        <div v-else>
          <md-menu
            md-align-trigger
            md-size="huge"
            :mdCloseOnSelect="true"
            :mdCloseOnClick="true"
            md-dense
          >
            <VasionButton
              id="home-inbox-actions-button"
              md-menu-trigger
              :isDisabled="!areThereCheckedDocuments"
              :classProp="'secondary'"
              @click.native="checkIfChecked"
            >
              Actions
            </VasionButton>
            <md-menu-content class="vasion-menuDivHeight">
              <md-menu-item v-if="canStartWorkflow" @click="startWorkflowMenuClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionWorkflowIcon2 />
                  </div>
                  <div class="vasion-menuText">
                    Start Workflow
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canLegalHoldDocument" @click="legalHoldDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionVaultIcon />
                  </div>
                  <div class="vasion-menuText">
                    Legal Hold
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canMoveDocument" @click="moveDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionArrowRightIcon />
                  </div>
                  <div class="vasion-menuText">
                    Move Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canCopyDocument" @click="copyDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionFileCopyIcon />
                  </div>
                  <div class="vasion-menuText">
                    Copy Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canEmailDocument" @click="emailSelectedDocuments()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionEmailIcon />
                  </div>
                  <div class="vasion-menuText">
                    Email
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canDownloadDocument" @click="downloadSelectedDocuments('download')">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionCloudDownloadIcon />
                  </div>
                  <div class="vasion-menuText">
                    Download
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canDeleteDocument || canRecycleDocument" @click="deleteDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionDeleteIcon />
                  </div>
                  <div class="vasion-menuText">
                    {{ deleteDocumentText }}
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canMergeDocuments" @click="showMergeDialog = !showMergeDialog">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionArrowMerge />
                  </div>
                  <div class="vasion-menuText">
                    Merge Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canPrintDocument" @click="showPrintModal = !showPrintModal">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionPrintIcon />
                  </div>
                  <div class="vasion-menuText">
                    Print Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canAccessThumbnailManager" @click="showThumbnailManager = true">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionPhotoGallery />
                  </div>
                  <div class="vasion-menuText">
                    Thumbnail Manager
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="areDocumentsPdfOrTiff" @click="toggleOcr()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionMediaEditingScanDocumentIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    OCR
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="areDocumentsPdfOrTiff" @click="toggleBarcodeRecognition()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionBarcodeIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    Barcode Recognition
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments" @click="goToCompareDocuments()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionColumnViewIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    Compare Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item @click="openNewTab()">
                  <div class="md-layout">
                    <div class="vasion-menuIcon">
                      <VasionOpenInNew class="fill-grey-400" />
                    </div>
                    <div class="vasion-menuText">
                      Open in New Browser Tab
                    </div>
                  </div>
                </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
        <div class="table-controls">
          <md-button
            id="btnRefresh"
            class="md-icon-button md-dense action-button"
            title="Refresh"
            @click="refreshData()"
          >
            <VasionRefreshIcon />
          </md-button>
          <div class="vasion-toolbar-divider" />
          <VasionPager
            :currentPageNumber="currentPageNumber"
            :totalPages="totalPages"
            @pageChanged="goToPage"
          />
        </div>
        <div v-if="!isDataRecentDocuments  && !isDataDrafts" id="filterListIconDiv" class="control-box">
          <md-button
            id="filterListIconBtn"
            :class="{ 'backgroundGrey' : showFilterDropDown}"
            class="md-icon-button md-dense action-button"
            title="Filter"
            @click="toggleFilterDropDown"
          >
            <VasionFilterListIcon  />
          </md-button>
          <VasionFilterToggle
            v-if="showFilterDropDown"
            :headerText="headerText"
            :workSpaceType="workSpaceType"
            @loseFocus="toggleFilterDropDown"
            @applyFilter="applyFilter"
          />
        </div>
      </div>
      <div class="secondary-table-container vasion-html-table-default">
        <VasionTable
          id="inbox-grid"
          :headerColumns="tableData.tableHeaders"
          :tableRows="tableData.tableRows"
          :hideColumns="hideColumns"
          :supportSorting="!isDataDrafts"
          @newList="setCheckedDocuments"
          @vasion-selection="handleRowSelect"
          @vasion-column-sort="handleColumnSort"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          :ellipsisOffset="-ellipsisOffset"
        />
      </div>
    </div>
    <!-- add dynamic event handlers and props -->
    <!-- https://vuejs.org/v2/guide/syntax.html#Dynamic-Arguments -->
    <!-- https://stackoverflow.com/questions/48250832/programmatically-bind-custom-events-for-dynamic-components-in-vuejs -->
    <md-dialog id="download-document-modal" :md-active.sync="showDownloadModal" :md-click-outside-to-close="false">
      <VasionDownloadOptions :supportBurnAnnotations="!areThereCertifiedDocuments" :multipleDocuments="multipleDocuments" @okClick="downloadDocument" @cancelClick="showDownloadModal = !showDownloadModal" />
    </md-dialog>
    <md-dialog id="email-modal" :md-active.sync="showEmailModal" :md-click-outside-to-close="false" class="email-modal-div">
      <DocumentEmail
        :supportBurnAnnotations="!areThereCertifiedDocuments"
        :documentIDString="checkedDocumentsString"
        @email-result="showSnackbar"
        @close="closeDocumentEmail"
      />
    </md-dialog>
    <VasionGeneralModal
      :modalTitle="modalConfig.modalTitle"
      :modalType="modalConfig.modalType"
      :buttonGroup="modalConfig.buttonGroup"
      :message="modalConfig.message"
      :confirmButtonCallback="modalConfig.confirmButtonCallback"
      :sync="showGeneralModal"
      :confirmButtonText="modalConfig.confirmButtonText"
      :rejectButtonText="modalConfig.rejectButtonText"
      @noButtonClick="showGeneralModal = !showGeneralModal"
      @confirmButtonClick="confirmButtonClick"
    >
      <component :is="modalConfig.slotComponentName" :hideButtons="modalConfig.hideButtons === 'true'" />
    </VasionGeneralModal>
    <VasionGeneralModal
      :hideButtons="true"
      :modalType="'slot'"
      :sync="showThumbnailManager"
      @noButtonClick="showThumbnailManager = false"
    >
      <ThumbnailManager v-if="showThumbnailManager" :documentId="Number(checkedDocuments[0])" @cancel="closeThumbnailManager($event)" />
    </VasionGeneralModal>
    <VasionGeneralModal
      :modalType="'slot'"
      :buttonGroup="'split'"
      :hideButtons="true"
      :includeCheckbox="true"
      :checkboxName="'Re-run Workflow step entry Logic'"
      :sync="showUpdateFieldsModal"
      :confirmButtonText="'Update Fields'"
      :rejectButtonText="'CANCEL'"
    >
      <UpdateFields
        ref="updateFieldsComponent"
        :selectedFormID="selectedFormID"
        @cancel="showUpdateFieldsModal = false"
        @updateFields="runWorkflowBulkUpdate"
        @checkboxChanged="toggleReRunWorkflowCheckbox"
      />
    </VasionGeneralModal>

    <md-dialog id="start-workflow-select-dialog" :md-active.sync="showStartWorkflow" :md-click-outside-to-close="false">
      <Loading
        class="vasion-loading-indicator"
        :active.sync="isWFLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <VasionSelectWorkflow
        v-if="workflowUIType === 'wfSelection'"
        :workflowList="workflowList"
        @cancelButtonClick="showStartWorkflow = false"
        @continueButtonClick="workflowSelected"
      />

      <VasionUserGroupSelect
        v-if="workflowUIType === 'selectUser'"
        modalTitle="Select User"
        :userOnly="true"
        :groupFilterId="assignUserGroupFilterId"
        @noButtonClick="showStartWorkflow = false"
        @yesButtonClick="workflowSelectUserOKClick"
      />
    </md-dialog>
    <md-dialog id="vasion-confirmation-div" :md-active.sync="showConfirmationDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="confirmationMessage" @noButtonClick="noConfirmationClick()" @yesButtonClick="yesConfirmationClick()" />
    </md-dialog>
    <md-dialog id="move-modal" :md-active.sync="showMoveModal" :md-click-outside-to-close="false" class="email-modal-div">
      <DocumentMove
        :documentIDString="checkedDocumentsString"
        @move-complete="moveCompleted"
        @close="closeDocumentMove"
      />
    </md-dialog>
    <VasionGeneralModal
      :hideButtons="true"
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Merge'"
      :modalType="'slot'"
      :sync="showMergeDialog"
      @noButtonClick="closeMergeModal"
      @confirmButtonClick="mergeDocuments"
    >
      <MergeFiles 
        @showSnackbar="showSnackbar" 
        @cancel="closeMergeModal"
      />
    </VasionGeneralModal>
    <md-dialog id="download-document-modal" :md-active.sync="showPrintModal" :md-click-outside-to-close="false">
      <VasionPrintOptions :supportBurnAnnotations="!areThereCertifiedDocuments" @okClick="printSelectedDocuments" @cancelClick="showPrintModal = !showPrintModal" />
    </md-dialog>
    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Run OCR'"
      modalTitle="OCR"
      :modalType="'slot'"
      :sync="showOcrDialog"
      @confirmButtonClick="ocrDocuments"
      @noButtonClick="showOcrDialog = !showOcrDialog"
    >
      <DocumentOcrPages @ocrAllPagesUpdated="updateOcrAllPages" />
    </VasionGeneralModal>
    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Start'"
      modalTitle="Barcode Recognition"
      :modalType="'slot'"
      :sync="showBarcodeDialog"
      @confirmButtonClick="sendDocBarcodeRecognition"
      @noButtonClick="showBarcodeDialog = !showBarcodeDialog"
    >
      <DocumentBarcodeRecognition ref="barcodeRecognition" />
    </VasionGeneralModal>
    <VasionSnackbar
      id="home-inbox-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <VasionGeneralModal
      id="delete-recycle-confirm-modal"
      :sync="showDeleteRecycleConfirmModal"
      :modalType="'slot'"
      :confirmButtonText="'Cancel'"
      :showRejectButton="true"
      :customButtonText="'Recycle'"
      :rejectButtonText="'Delete'"
      :customButtonDisabled="false"
      :showCustomButton="true"
      :modalTitle="'Recycle / Delete Documents'"
      :message="' Would you like to delete the document(s), or move them to the recycle bin?'"
      :showTitleAndMessage="true"
      @confirmButtonClick="showDeleteRecycleConfirmModal = !showDeleteRecycleConfirmModal"
      @customButtonClick="confirmRecycle"
      @noButtonClick="confirmDelete"
    />

    <VasionGeneralModal
      id="draft-delete-confirmation"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="deleteMessage"
      :sync="showDeleteDialog"
      @confirmButtonClick="deleteDraft"
      @noButtonClick="showDeleteDialog = !showDeleteDialog"
    />

    <md-dialog
      id="start-workflow-select-dialog"
      :md-click-outside-to-close="false"
      :md-active.sync="showWorkflowDraft"
    >
      <StartWorkflowManager 
        v-if="showWorkflowDraft"
        :loadWorkflowId="selectedWorkflowID"
        :loadDraftId="selectedDraftID"
        :loadFormId="selectedFormID"
        :viewMode="true" 
        @closeStartWorkflow="closeStartWorkflow" 
      />
    </md-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import DocumentBarcodeRecognition from '@/components/document/DocumentBarcodeRecognition.vue';
import DocumentEmail from '@/components/document/DocumentEmail.vue';
import DocumentOcrPages from '@/components/document/DocumentOcrPages.vue';
import VasionSnackbar from '@/components/shared/VasionSnackbar.vue';
import DocumentMove from '@/components/document/DocumentMove.vue';
import MergeFiles from '@/components/document/MergeFiles.vue';
import UpdateFields from '@/components/workflow/UpdateFields.vue';
import StartWorkflowManager from '@/components/workflow/StartWorkflowManager.vue'


// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import ThumbnailManager from '@/components/document/ThumbnailManager.vue';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { removeIllegalChars, testDownloadHasFileExtension } from '@/store/helperModules/common.module'

export default {
  name: 'TheHomeInbox',
  components: {
    DocumentBarcodeRecognition,
    DocumentEmail,
    DocumentMove,
    DocumentOcrPages,
    Loading,
    MergeFiles,
    ThumbnailManager,
    VasionSnackbar,
    UpdateFields,
    StartWorkflowManager,
  },
  data: function () {
    return {
      actionsConfig: {
        approveDisable: false,
        reassignDisable: false,
        rejectDisable: false,
        thumbnailManagerDisabled: true,
        canAccess: true,
      },
      approvableDocuments: [],
      areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments: false,
      areThereCertifiedDocuments: false,
      assignedUsers: [],
      attributeForm: '',
      assignUserGroupFilterId: 0,
      canCopyDocumentCurrentFolder: false,
      checkedDocumentsAreTiffOrPDF: false,
      checkAllSelected: false,
      checkedDocuments: [],
      checkedDocumentsWithSteps: [],
      confirmationMessage: '',
      confirmationType: '',
      deleteMessage: '',
      deleteDraftId: 0,
      ellipsisButtonConfig: {
        buttons: [
          {
            id: 'edit',
            name: 'Edit',
            icon: 'VasionEditIcon'
          },
          {
            id: 'delete',
            name: 'Delete',
            icon: 'VasionDeleteIcon'
          },
        ],
      },
      ellipsisOffset: 0,
      favorites: ['', '', '', ''],
      inaccessibleDocuments: [],
      isWFLoading: false,
      loaderBackgroundColor,
      loaderColor,
      modalConfig: {
        buttonGroup: 'end',
        confirmButtonText: 'Yes',
        message: '',
        modalId: '',
        modalKey: '',
        modalTitle: '',
        modalType: 'message',
        rejectButtonText: 'No',
      },
      multipleDocuments: false,
      objectField: '',
      ocrAllPages: false,
      recents: ['', '', '', ''],
      reRunWorkflowStep: true,
      showActions: false,
      showApproveDialog: false,
      showBarcodeDialog: false,
      showFilterDropDown: false,
      showThumbnailManager: false,
      reassignableDocuments: [],
      rejectableDocuments: [],
      selectedDraftID: 0,
      selectedFormID: 0,
      selectedWorkflowID: 0,
      showConfirmationDialog: false,
      showCopyModal: false,
      showDeleteDialog: false,
      showDeleteRecycleConfirmModal: false,
      showDownloadModal: false,
      showEmailModal: false,
      showGeneralModal: false,
      showMergeDialog: false,
      showMoveModal: false,
      showOcrDialog: false,
      showPrintModal: false,
      showSnackbarBool: false,
      showUpdateFieldsModal: false,
      showStartWorkflow: false,
      showWorkflowDraft: false,
      singleDocument: '',
      sortAscending: true,
      sortBy: 0,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableDataBuffer: '',
      tempDocumentID: 0,
      tempWorkflowID: 0,
      today: new Date(),
      userAssignPayload: {},
      VasionUserGroupSelect: 'VasionUserGroupSelect',
      workflowActionsActive: true,
      workflowUIType: 'wfSelection',
      workflowList: [],
    }
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
    areDocumentsPdfOrTiff() { return this.checkedDocumentsAreTiffOrPDF },
    areThereCheckedDocuments() { return this.checkedDocuments && this.checkedDocuments.length > 0 },
    areThereMoreThenOneCheckedDocuments() { return this.checkedDocuments && this.checkedDocuments.length > 1 },
    canAccessThumbnailManager() {
      return this.$store.getters['common/canAccessThumbnailManager']
        && !this.areThereMoreThenOneCheckedDocuments
        && this.checkedDocumentsAreTiffOrPDF
        && !this.areThereCertifiedDocuments
    },
    canCopyDocument() { return this.$store.getters['common/canCopyDocument'] && this.canCopyDocumentCurrentFolder },
    canDeleteDocument() { return this.$store.getters['common/canDeleteDocument'] },
    canDownloadDocument() { return this.$store.getters['common/canDownloadDocument'] },
    canEmailDocument() { return this.$store.getters['common/canEmailDocument'] },
    canLegalHoldDocument() { return this.$store.getters['common/canLegalHold'] },
    canMergeDocuments() { return this.$store.getters['common/canMergeDocuments'] && this.areThereMoreThenOneCheckedDocuments && !this.areThereCertifiedDocuments },
    canMoveDocument() { return this.$store.getters['common/canMoveDocument'] },
    canPrintDocument() { return this.$store.getters['common/canPrintDocument'] },
    canRecycleDocument() { return this.$store.getters['common/canRecycleDocument'] },
    canStartWorkflow() { return this.$store.getters['common/canAssignToWorkflow'] },
    checkedDocumentsString() { return !this.checkedDocuments || this.checkedDocuments.length === 0 ? '' : this.checkedDocuments.join(',') },
    currentPage() {
      return this.$store.state.mainViews.mainNav
    },
    currentPageNumber() { return !this.$store.state.workflow.workSpaceCurrentPage ? 1 : this.$store.state.workflow.workSpaceCurrentPage },
    deleteDocumentText() { return this.canDeleteDocument && this.canRecycleDocument ? "Delete / Recycle" : (this.canDeleteDocument ? "Delete" : "Recycle") },
    eForm() { return this.$store.state.forms.eForm },
    headerText() { return this.isCompletedData ? 'Completed' : 'Pending' },
    hideColumns() {
      if (this.isDataDrafts) {
        return ['_ID_','_EFormID_','_WFID_']
      }
      switch (this.workSpaceType) {
        case 1:
        case 3:
        return ['_documentID_', '_wFID_', '_stepID_', 'StepID', 'Step Name']
        default:
        return ['_documentID_', '_wFID_', '_stepID_', 'StepID']
      }
    },
    isAdminUser() { return this.$store.getters['common/isAdminUser'] },
    isCompletedData() { return this.$store.state.mainViews.mainNav && this.$store.state.mainViews.mainNav.endsWith('-completed') },
    isDataDrafts() { return this.$store.state.workflow.isDataDrafts },
    isDataRecentDocuments() { return this.$store.state.workflow.isDataRecentDocuments },
    isLoading() { return this.$store.state.workflow.isLoading },
    pageSize() { return this.$store.state.common.pageSize },
    pageSizeOptions() { return this.$store.state.common.pageSizeOptions },
    tableData() { return this.$store.state.workflow.workSpaceData },
    totalPages() { return !this.$store.state.workflow.workSpaceTotalPages ? 1 : this.$store.state.workflow.workSpaceTotalPages },
    userID() { return this.$store.state.common.userID },
    workSpaceType() { return this.$store.state.workflow.workSpaceType },
    workSpaceDataRaw() { return this.$store.state.workflow.workSpaceDataRaw },
  },
  watch: {
    checkedDocuments: async function () {
      const notPermittedToReject = []
      const notPermittedToReassign = []
      const notPermittedToApprove = []
      this.actionsConfig.canAccess = true
      Object.values(this.checkedDocuments).forEach((checkedDocument) => {
        if (this.inaccessibleDocuments.includes(checkedDocument)) {
          this.actionsConfig.canAccess = false
        }
      })
      Object.values(this.checkedDocuments).forEach((checkedDocument) => {
        if (!this.rejectableDocuments.includes(checkedDocument)) {
          notPermittedToReject.push(checkedDocument)
        }
      })
      Object.values(this.checkedDocuments).forEach((checkedDocument) => {
        if (!this.reassignableDocuments.includes(checkedDocument)) {
          notPermittedToReassign.push(checkedDocument)
        }
      })
      Object.values(this.checkedDocuments).forEach((checkedDocument) => {
        if (!this.approvableDocuments.includes(checkedDocument)) {
          notPermittedToApprove.push(checkedDocument)
        }
      })
      
      this.actionsConfig.approveDisable = notPermittedToApprove.length > 0
      this.actionsConfig.reassignDisable = notPermittedToReassign.length > 0
      this.actionsConfig.rejectDisable = notPermittedToReject.length > 0
      
      this.workflowActionsActive = true
      if (!this.isAdminUser) { 
        Object.values(this.assignedUsers).forEach((assignedUser) => {
          if (assignedUser) { 
            if (assignedUser.toLowerCase() !== "me") {
              this.workflowActionsActive = false
            }
          }
        })
      }
    },
    modalConfig: function () { this.$forceUpdate() },
    pageSize: function (newVal) {
      this.goToPage(1)
      this.$store.dispatch('workflow/setWorkSpacePageSize', newVal)
    },
    
    tableData: function (newVal) {
      this.checkedDocuments = []
      this.updateWorkflowActions(this.workSpaceDataRaw)

      const tableData = JSON.stringify(newVal)
      if (tableData === this.tableDataBuffer) return
      this.tableDataBuffer = JSON.stringify(newVal)
      this.workspacePageViewed(this.currentPage, newVal.tableRows.length)
    }
  },
  async created() {
    this.refreshData()
    if (this.$route.params.previousPage && this.currentPageNumber === 1) {
      this.$store.dispatch('workflow/setWorkSpaceCurrentPage', this.$route.params.previousPage)
    }

    if (this.$route.query.workspaceType) {
      const workspaceType = parseInt(this.$route.query.workspaceType) ? parseInt(this.$route.query.workspaceType) : 0
      const pageSize = parseInt(this.$route.query.pageSize) ? parseInt(this.$route.query.pageSize) : this.pageSize
      const page = parseInt(this.$route.query.page) ? parseInt(this.$route.query.page) : this.currentPageNumber

      await this.$store.dispatch('common/setPageSize', pageSize)
      await this.$store.dispatch('workflow/setWorkSpaceCurrentPage', page)
      await this.$store.dispatch('workflow/getWorkflowPendingTasks', workspaceType)
    }

    if (this.$route.query.recentDocuments) {
      const pageSize = parseInt(this.$route.query.pageSize) ? parseInt(this.$route.query.pageSize) : this.pageSize
      const page = parseInt(this.$route.query.page) ? parseInt(this.$route.query.page) : this.currentPageNumber

      await this.$store.dispatch('common/setPageSize', pageSize)
      await this.$store.dispatch('workflow/setWorkSpaceCurrentPage', page)

      await this.goToPage(page)
    }
  },
  methods: {
    async actionsClick() {
      this.actionsConfig.thumbnailManagerDisabled = true
      this.areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments = false
      if (this.checkedDocuments.length > 0) {
        const documentFlags = await this.$store.dispatch('document/checkDocumentFlags', { Values: this.checkedDocuments })
        this.areThereCertifiedDocuments = documentFlags.documentsCertified
 
        this.showActions = !this.showActions
        if (this.checkedDocuments.length === 1) {
          const payload = { documentID: this.checkedDocuments[0], fileExtensions: ['pdf', 'tif', 'tiff'] }
          this.actionsConfig.thumbnailManagerDisabled = await !this.$store.getters['common/canAccessThumbnailManager']
            || await !this.$store.dispatch('document/checkFileExtension', payload) === 'True'
        }
        if (this.checkedDocuments.length === 2 || this.checkedDocuments.length === 3) { // For Comparing Documents
          const promiseArr = []
          this.checkedDocuments.forEach(docId => {
            const payload = { documentID: docId, fileExtensions: ['pdf', 'tif', 'tiff'] }
            promiseArr.push(this.$store.dispatch('document/checkFileExtension', payload))
          })
          const promises = await Promise.all(promiseArr)

          this.areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments = !promises.includes('False')
        }
      }
    },
    applyFilter() {
      this.toggleFilterDropDown()
      this.refreshData()
    },
    async approveDocuments() {
      const payloadForDocumentsWithSteps = {
        ApprovalList: this.checkedDocumentsWithSteps,
        Password: '',
      }

      const response = await this.$store.dispatch('document/approveDocumentsWithSteps', payloadForDocumentsWithSteps)
      if (!response) {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was a problem approving the selected workflows.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      } else if (response.Value) {
        this.snackbarTitle = 'Error approving: '
        this.snackbarSubTitle = response.Value
        this.snackbarImage = false
        this.showSnackbarBool = true
      } else {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Workflows approved'
        this.snackbarImage = true
        this.showSnackbarBool = true
        this.refreshData()
        await this.$amplitude.trackEvent('Workflow Doc Reviewed', {workflowAction: 'Approve', isMultipleDocs: true})
      }
      this.showGeneralModal = !this.showGeneralModal
    },
    checkIfChecked() {
      this.updateCheckedDocumentsAreTiffOrPDF()
      return this.areThereCheckedDocuments ? null : this.showSelectionSnackbar()
    },
    closeDocumentEmail() { this.showEmailModal = false },
    closeDocumentMove() { this.showMoveModal = false },
    closeMergeModal() {
      this.showMergeDialog = false;
      this.$store.dispatch('document/setMergeClearValues')
    },
    closeStartWorkflow() {
      this.showWorkflowDraft = false
      this.refreshData()
    },
    closeThumbnailManager(event) {
      if (event) {
        if (event.Value !== '') {
          this.showSnackbarBool = true
          this.snackbarImage = false
          this.snackbarSubTitle = event.Value
          this.snackbarTitle = 'Error Saving Thumbnail Manager changes'
        } else {
          this.showSnackbarBool = true
          this.snackbarImage = true
          this.snackbarTitle = 'Saved Thumbnail Manager changes'
          this.refreshData()
        }
      }
      this.showThumbnailManager = false
    },
    async confirmDelete() {
      this.showDeleteRecycleConfirmModal = false
      this.deleteSelectedDocuments(false)
    },
    async confirmRecycle() {
      this.showDeleteRecycleConfirmModal = false
      this.deleteSelectedDocuments(true)
    },
    copyDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.showCopyModal = true
    },
    async confirmButtonClick(modalPayload, modalCallback) { await modalCallback(modalPayload) },
    deleteDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      if (this.canDeleteDocument && this.canRecycleDocument) {
        this.showDeleteRecycleConfirmModal = true
      }
      else
      {
        this.confirmationMessage = 'Are you sure you want to ' + (this.canDeleteDocument ? 'delete' : 'recycle')  + ' the selected Document(s)?'
        this.confirmationType = this.canDeleteDocument ? 'Delete' : 'Recycle'
        this.showConfirmationDialog = true
      }
    },
    async deleteDraft() {
      this.showDeleteDialog = false
      this.$store.dispatch('workflow/setIsLoading', true)
      this.$amplitude.trackEvent('Form Draft Deleted', {} )
      const response = await this.$store.dispatch('forms/deleteEFormDraftById', this.deleteDraftId)

      if (response && response?.Value) {
        this.snackbarTitle = "Form Draft"
        this.snackbarSubTitle = "The form draft was successfully deleted."
        this.snackbarImage = true
        this.showSnackbarBool = true
        this.refreshData()
        this.deleteDraftId = 0
      } else {
        this.snackbarTitle = "Form Draft"
        this.snackbarSubTitle = response?.ResultMessage ? response?.ResultMessage : `An error occurred during deletion of the draft form.`
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
      this.$store.dispatch('workflow/setIsLoading', false)
    },    
    async deleteSelectedDocuments(forceRecycle) {
      this.isLoading = true
      const errorMessage = await this.$store.dispatch('document/deleteMultipleDocuments', { 
        'DocumentIdString': this.checkedDocumentsString, 
        'ForceRecycle': forceRecycle 
      })
      const deleteSuccess = errorMessage === ''

      if (deleteSuccess) {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'The Documents have been ' + (forceRecycle || !this.canDeleteDocument ? 'moved to the recycle bin' : 'deleted')
        this.snackbarImage = true
      } else {
        this.snackbarTitle = 'Error ' +  (forceRecycle || !this.canDeleteDocument ? 'moving' : 'deleting') + ' Documents'
        this.snackbarSubTitle = errorMessage
        this.snackbarImage = false
      }

      this.isLoading = false
      this.showSnackbarBool = true;

      // we are refreshing on success or not - if user tried to delete multiple documents, one might have failed, but the rest were deleted
      this.refreshData()
    },
    async downloadDocument(options) {
      const downloadPayload = {
        T: this.checkedDocumentsString,
        BurnAnnotations: options.burnAnnotations,
        UserID: this.userID,
        IncludeWatermark: false,
        ExtractPages: options.extractPages,
        ConvertToPDF: options.convertToPDF,
        StreamPDF: '',
      }
      const downloadResult = await this.$store.dispatch('document/downloadVasionDocument', downloadPayload)
      if (downloadResult) {
        const fileData = await this.$store.dispatch('document/getDocumentDetails', { documentID: this.checkedDocuments[0] })
        const newFileName = await removeIllegalChars(fileData.DisplayName)

        // eslint-disable-next-line
        const fileDownload = require('js-file-download')
        let localFileExtension = ''
        if (this.multipleDocuments) {
          localFileExtension = '.zip'
          fileDownload(downloadResult, `VasionDownload${localFileExtension}`)
        } else if (downloadPayload.ConvertToPDF === true) {
          localFileExtension = '.pdf'
          fileDownload(downloadResult, `${newFileName}${localFileExtension}`)
        } else {
          const docInfoPayload = {
            DocumentId: this.checkedDocuments[0],
            IncludeFileInfo: false,
            IncludeIndexForm: false,
            IncludeNotes: false,
            IncludeWorkflow: false,
            IncludeLinks: false,
            IncludeVersions: false,
          }
          const docInfoResult = await this.$store.dispatch('document/getDocumentDetailsWithPayload', docInfoPayload)
          if (docInfoResult && docInfoResult.DocumentName && docInfoResult.DocumentName.includes('.')) {
            localFileExtension = docInfoResult.DocumentName.substring(docInfoResult.DocumentName.lastIndexOf('.'))
          }

          testDownloadHasFileExtension(downloadResult, newFileName, localFileExtension)
        }
      } else {
        this.snackbarTitle = 'Error Downloading Document(s)'
        this.snackbarSubTitle = 'Unable to download the selected Document(s)'
        this.snackbarImage = false
        this.showSnackbarBool = true;
      }
      this.showDownloadModal = false
    },
    downloadSelectedDocuments() {
      this.showActions = false
      this.multipleDocuments = false
      if (this.checkedDocuments.length < 1) {
        return;
      }
      if (this.checkedDocuments.length > 1) {
        this.multipleDocuments = true
      }
      this.showDownloadModal = true
    },
    editDraft(draftId, formId, workflowId) {
      this.$amplitude.trackEvent('Form Draft Edited', {} )
      this.$store.dispatch('forms/getEForm', formId)
      this.selectedDraftID = draftId
      this.selectedWorkflowID = workflowId
      this.selectedFormID = formId
      this.showWorkflowDraft = true
    },    
    async ellipsisButtonClicked(payload) {
      if (this.isDataDrafts) {
        if (payload?.buttonId && payload?.item?.Values) {
          const draftId = payload.item.Values[0]
          const formId = payload.item.Values[1]
          const workflowId = payload.item.Values[2]
          const draftName = payload.item.Values[3]
          switch (payload.buttonId) {
            case 'edit':
              this.editDraft(draftId, formId, workflowId)
              break;
            case 'delete':
              this.deleteMessage = `Are you sure you want to delete "${draftName}"?`
              this.deleteDraftId = draftId
              this.showDeleteDialog = true
              break;
            default:
              break;
          }
        }
      }
    },
    emailSelectedDocuments() {
      this.showActions = false
      if (this.checkedDocumentsString === '') {
        return
      }
      this.showEmailModal = true
    },
    failedModal(failedMessage) {
      this.modalConfig.modalId = 'failed-modal'
      this.modalConfig.modalKey = 'failed-modal'
      this.modalConfig.modalType = 'message'
      this.modalConfig.buttonGroup = 'single'
      this.modalConfig.rejectButtonText = 'Ok'
      this.modalConfig.message = `${failedMessage}`
      this.showGeneralModal = true
    },
    async goToCompareDocuments() {
      await this.$store.dispatch('document/setBackRoute', '/workspace/inbox')
      this.$router.push({ name: 'TheCompareDocuments', params: { documentIDs: this.checkedDocuments } })
    },
    async goToPage(pageNumber) {
      let localPageNumber = pageNumber

      if (!pageNumber || pageNumber <= 0) {
        localPageNumber = 1
      } else if (pageNumber > this.totalPages) {
        localPageNumber = this.totalPages
      }

      await this.$store.dispatch('workflow/setWorkSpaceCurrentPage', localPageNumber)
      this.refreshData()
    },
    handleColumnSort(sortData) {
      if (this.isRealData === false || !sortData || !sortData.columnName) {
        return
      }

      // let sortType = 0
      switch (sortData.columnName) {
        case '_Priority_':
          sortData.columnName = 'Priority'
          break
        default:
          break
      }

      Promise.all([
        this.$store.dispatch('workflow/setWorkSpaceSortBy', sortData.columnName),
        this.$store.dispatch('workflow/setWorkSpaceSortAscending', sortData.ascending && sortData.ascending === true),
      ]).finally(() => {
        this.refreshData()
      })
    },
    async handleRowSelect(selectInfo) {
      if (this.isDataDrafts) {
        const draftId = selectInfo.Values[0]
        const formId = selectInfo.Values[1]
        const workflowId = selectInfo.Values[2]
        this.editDraft(draftId, formId, workflowId)
        return
      }
      if (selectInfo.Values && selectInfo.Values[1] && selectInfo.Values[1] !== '' && selectInfo.Values[1] !== '0') {
        const documentId = selectInfo.Values[1]
        const workflowStepId = this.isDataRecentDocuments ? 0 : selectInfo.Values[3]
        await this.$store.dispatch('document/setBackRoute', '/workspace/inbox')

        const shouldOpenInDataView = await this.$store.dispatch('workflow/shouldOpenInDataView', documentId)
        if (shouldOpenInDataView === true) {
          this.$router.push({ name: 'DocumentData', params: { documentID: documentId, workflowStepId: workflowStepId, previousPage: this.currentPageNumber } })
        } else {
          this.$router.push({ name: 'Document', params: { documentID: documentId, workflowStepId: workflowStepId, previousPage: this.currentPageNumber } })
        }
      }
    },
    moveDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.showMoveModal = true
    },
    noConfirmationClick() { this.showConfirmationDialog = false },
    legalHoldDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.confirmationMessage = 'Are you sure you want to place a legal hold on the selected Document(s)?'
      this.confirmationType = 'LegalHold'
      this.showConfirmationDialog = true
    },
    async legalHoldSelectedDocuments() {
      const errorMessage = await this.$store.dispatch('document/legalHoldDocuments', this.checkedDocumentsString)
      const deleteSuccess = errorMessage === ''

      if (deleteSuccess) {
        this.snackbarTitle = 'Documents legally held successfully'
        this.snackbarSubTitle = 'The Documents have been legally held'
        this.snackbarImage = 'check-circle-24-px.png';
      } else {
        this.snackbarTitle = 'Error legally holding Documents'
        this.snackbarSubTitle = errorMessage
        this.snackbarImage = 'error-24-px.png';
      }

      this.showSnackbarBool = true;

      this.refreshData()
    },
    mergeDocuments() {
      this.$store.dispatch('document/setMergeKeyToRunMerge')
    },
    moveCompleted(moveResultMessage) {
      this.snackbarTitle = !moveResultMessage ? 'Document(s) successfully moved!' : 'Unable to move one or more Documents'
      this.snackbarSubTitle = moveResultMessage
      this.snackbarImage = moveResultMessage
      this.showSnackbarBool = true
      this.showMoveModal = false
      this.refreshData()
    },
    async ocrDocuments() {
      const payload = {
        documentIDs: this.checkedDocuments,
        ocrAllPages: this.ocrAllPages,
      }

      const response = await this.$store.dispatch('document/saveDocumentsForOCR', payload)
      this.toggleOcr()
      this.checkedDocuments = []

      if (response?.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'An email will be sent when the documents have been OCRed.'
        this.snackbarImage = true
      } else {
         this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.message = 'Something went wrong, please contact admin'
        this.snackbarImage = false
      }
      this.refreshData()
    },
    openNewTab() {
      if (this.checkedDocumentsString === '') {
        return
      }
      let popupsBlocked = false
      this.checkedDocuments.forEach(documentID => {
        const query = {
          ...(this.isDataRecentDocuments && { recentDocuments: true }),
          ...(!this.isDataRecentDocuments && { workspaceType: this.workSpaceType }),
          pageSize: this.pageSize,
          page: this.currentPageNumber,
        }
        const routeData = this.$router.resolve({ name: 'Document', params: { documentID: documentID }, query: query })
        const newTab = window.open(routeData.href, '_blank')
        if (newTab == null || typeof(newTab)=='undefined') {
          popupsBlocked = true
        }
      });
      if (popupsBlocked) alert('Please disable your pop-up blocker and try again.')
    },
    async printSelectedDocuments(settings) {
      this.showGeneralModal = false
      const payload = {
        T: this.checkedDocumentsString,
        BurnAnnotations: settings.burnAnnotations,
        ConvertToPDF: true,
        UserID: this.userID,
      }
      const documentsForPrint = await this.$store.dispatch('workflow/downloadVasionDocumentsForPrint', payload)
      // eslint-disable-next-line
      const printJS = require('print-js')
      if (documentsForPrint) {
        printJS({
          printable: documentsForPrint.Value, type: 'pdf', showModal: true, onPrintDialogClose: () => { this.$store.dispatch('workflow/deletePrintedFile', documentsForPrint) },
          onError: () => { 
            this.showSnackbarBool = true
            this.snackbarTitle = 'Error!'
            this.snackbarSubTitle = 'Error printing file, check "chrome://settings/content/pdfDocuments" and set to "Open PDFs in Chrome"'
            this.snackbarImage = false
          },          
        });
      }
    },
    async reassignWorkflow(payload) {
      const { Value } = await this.$store.dispatch('workflow/reassignWorkflow',
        {
          DocIds: this.checkedDocumentsString,
          reassignUserID: payload.username ? payload.value : null,
          reassignGroupID: payload.username ? null : payload.value,
        })
      this.showGeneralModal = false
      if (Value.length !== 0) {
        this.snackbarImage = false
        this.snackbarTitle = 'Error Reassigning'
        this.snackbarSubTitle = `Can't reassign document "${Value}".\nIf the problem persists, please contact your Administrator.`
        this.showSnackbarBool = true
      } else {
        await this.$amplitude.trackEvent('Workflow Doc Reviewed', {workflowAction: 'Reassign', isMultipleDocs: true})
      }
      this.refreshData()
    },
    async refreshData() {
      this.$store.dispatch('workflow/setIsLoading', true)
      if (this.isDataDrafts) {
        await this.$store.dispatch('workflow/loadMyEFormDraftsWorkflow')
        this.checkedDocuments = []
        this.$store.dispatch('workflow/setIsLoading', false)
        return
      }
      let userDefaultViewPromise = this.$store.dispatch('admin/getUserSetting', 'defaultView')
      this.$store.dispatch('common/getUsers')
      this.$store.dispatch('common/getGroups')
      if (this.isDataRecentDocuments) {
        await this.$store.dispatch('workflow/getWorkflowRecentDocuments')
        this.checkedDocuments = []
        this.$store.dispatch('workflow/setIsLoading', false)
      } else {
        await userDefaultViewPromise
        await this.$store.dispatch('workflow/getWorkflowPendingTasks')
         
        this.checkedDocuments = []
        this.$store.dispatch('workflow/setIsLoading', false)
      }
    },
    async rejectDocuments() {
      const { Value } = await this.$store.dispatch('document/rejectDocument', { Value: this.checkedDocumentsString })
      this.showGeneralModal = false
      if (Value.length !== 0) {
        this.snackbarImage = false
        this.snackbarTitle = 'Error Rejecting'
        this.snackbarSubTitle = Value
        this.showSnackbarBool = true
      } else {
        await this.$amplitude.trackEvent('Workflow Doc Reviewed', {workflowAction: 'Reject', isMultipleDocs: true})
      }
      this.refreshData()
    },
    async runWorkflowBulkUpdate() {
      this.$store.dispatch('workflow/setIsLoading', true)

      const documentStepPairs = this.checkedDocumentsWithSteps.map(doc => {
        return {
          documentID: doc.DocumentId,
          stepID: doc.StepId,
        }
      })
      let fieldValues = []

      if (this.$refs.updateFieldsComponent.localFieldValues && this.$refs.updateFieldsComponent.localFieldValues.length > 0) {
        fieldValues = this.$refs.updateFieldsComponent.localFieldValues.map(v => {
          const newElement = {
            name: v.name
          }

          if (Array.isArray(v.value)) {
            if (v.value.length == 1) {
              newElement.value = v.value[0].name
            } else {
              newElement.value = Array.prototype.map.call(v.value, a => a.name).join(';')
            }
          } else {
            newElement.value = v.value
          }

          return newElement
        })
      }

      const payload = {
        rerunStepEntryLogic: this.reRunWorkflowStep,
        objectID: this.$refs.updateFieldsComponent.attributeForm.value,
        fieldValues: fieldValues,
        documentStepPairs: documentStepPairs,
      }
      this.showUpdateFieldsModal = false
      const response = await this.$store.dispatch('workflow/runWorkflowBulkUpdate', payload)

      if (response === 'True') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Update Fields was successful'
        this.snackbarImage = true
      } else {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error updating the fields'
        this.snackbarImage = false
      }
      this.refreshData()
      this.$store.dispatch('workflow/setIsLoading', false)
    },
    showSelectionSnackbar() {
      this.snackbarTitle = 'Nothing Selected!'
      this.snackbarSubTitle = 'Please make a selection before performing an action.'
      this.snackbarImage = false
      this.showSnackbarBool = true;
    },
    async sendDocBarcodeRecognition() {
      const payload = this.$refs.barcodeRecognition.buildPayload()
      payload.documentIDs = this.checkedDocuments

      const response = await this.$store.dispatch('document/saveDocumentsForBarcodeRecognition', payload)
      this.toggleBarcodeRecognition()
      this.checkedDocuments = []

      if (response?.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'An email will be sent when the barcode process has completed.'
        this.snackbarImage = true
      } else {
         this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.message = 'Something went wrong, please contact admin'
        this.snackbarImage = false
      }
      this.refreshData()
    },
    setCheckedDocuments(newList) {
      this.checkedDocuments = newList.map(item => {
        return item.Values[1]
      })
      this.setCheckedDocumentsWithSteps(newList)
      this.assignedUsers = newList.map(item => {
        return item.Values[8]
      })
    },
    setCheckedDocumentsWithSteps(newList) {
      this.checkedDocumentsWithSteps = newList.map(document => {
        return { DocumentId: parseInt(document.Values[1], 10), StepId: parseInt(document.Values[3], 10) }
      })
    },
    showSnackbar(value) {
      const [response] = value
      this.snackbarTitle = response ? 'Email successfully sent!' : 'Unable to send email';
      this.snackbarSubTitle = response ? 'Thank you for using Vasion\'s email service' : 'Please try again or contact your administrator';
      this.snackbarImage = response
      this.showSnackbarBool = true;
    },
    async startWorkflowMenuClick() {
      if (!this.areThereCheckedDocuments) {
        return
      }

      this.workflowList = await this.$store.dispatch('workflow/getWorkflowMenuItems')
      this.userAssignPayload = null
      this.workflowUIType = 'wfSelection'
      this.selectedWorkflowID = 0
      this.showStartWorkflow = true
    },
    async takeOwnershipDocuments() {
      const payload = { DocumentIdString: this.checkedDocumentsString }
      const { sGenRes } = await this.$store.dispatch('document/takeOwnershipDocuments', payload)
      this.showGeneralModal = !this.showGeneralModal
      if (sGenRes) {
        this.snackbarImage = false
        this.snackbarTitle = 'Error Taking Ownership'
        this.snackbarSubTitle = sGenRes
        this.showSnackbarBool = true
      } else {
        await this.$amplitude.trackEvent('Workflow Doc Reviewed', {workflowAction: 'Take Ownership', isMultipleDocs: true})
      }
      this.refreshData()
    },
    async terminateSelectedDocuments() {
      const { data: { sGenRes } } = await this.$store.dispatch('document/terminateDocuments', { DocumentIdString: this.checkedDocumentsString })
      this.showGeneralModal = false
      if (sGenRes.length !== 0) {
        this.snackbarImage = false
        this.snackbarTitle = 'Error Terminating'
        this.snackbarSubTitle = sGenRes
        this.showSnackbarBool = true
      } else {
        await this.$amplitude.trackEvent('Workflow Doc Reviewed', {workflowAction: 'Terminate', isMultipleDocs: true})
      }
      this.refreshData()
    },
    toggleBarcodeRecognition() { this.showBarcodeDialog = !this.showBarcodeDialog },
    toggleFailedModal() { this.showGeneralModal = !this.showGeneralModal },
    toggleFilterDropDown() { this.showFilterDropDown = !this.showFilterDropDown },
    toggleGeneralModal(modalConfig, functionName) {
      if (this.checkedDocuments.length > 0) {
        this.modalConfig = modalConfig
        this.modalConfig.confirmButtonCallback = functionName
        this.showActions = false
        this.showGeneralModal = true
      }
    },
    toggleReRunWorkflowCheckbox(value) {
      this.reRunWorkflowStep = value
    },
    toggleOcr() { this.showOcrDialog = !this.showOcrDialog },
    async tryStartWorkflow() {
      // try submitting the first document to see if we need to do any prompting
      this.isWFLoading = true
      if (!this.userAssignPayload) {
         const userIdInt = parseInt(this.userID, 10)
        this.userAssignPayload = {
            documentIDList: this.checkedDocuments.filter(a => a),
            workflowId: this.selectedWorkflowID,
            userId: userIdInt,
        }
      }
      const responsePayload = await this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', this.userAssignPayload)
      if (responsePayload) {
        if (responsePayload.assignUserGroupFilterId > 0) {
          // need to prompt the user to choose an approver from a group
          this.assignUserGroupFilterId = responsePayload.assignUserGroupFilterId
          this.userAssignPayload = responsePayload.userAssignPayload
          this.workflowUIType = 'selectUser'
        } else {
          // unable to send the document to workflow
          this.snackbarTitle = 'Workflow Failure'
          this.snackbarSubTitle = 'Unable to add one or more Documents to Workflow.'
          this.snackbarImage = false
          this.showStartWorkflow = false
          this.showSnackbarBool = true
        }
        this.isWFLoading = false
        return
      }

      // the first document was added and a prompt was not needed, so do the rest!
      const promiseArray = this.checkedDocuments.map((documentID, index) => {
        if (index === 0) {
          return null
        }

        this.userAssignPayload.documentId = documentID
        return this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', this.userAssignPayload)
      })

      // start the remaining workflows and check the results
      const resultArray = await Promise.all(promiseArray)
      let success = true

      if (resultArray && resultArray.length > 0) {
        resultArray.map((element) => {
          if (element !== null) {
            success = false
          }

          return true
        })
      }

      this.snackbarTitle = success ? 'Success' : 'Workflow Failure'
      this.snackbarSubTitle = success ? 'Workflow Started Successfully!' : 'Unable to add one or more Documents to Workflow.'
      this.snackbarImage = success
      this.refreshData()
      this.showStartWorkflow = false
      this.showSnackbarBool = true
      this.isWFLoading = false
    },
    async updateCheckedDocumentsAreTiffOrPDF() {
      const documentFlags = await this.$store.dispatch('document/checkDocumentFlags', { Values: this.checkedDocuments })
      this.areThereCertifiedDocuments = documentFlags.documentsCertified

      const promiseArr = []
      this.checkedDocuments.forEach(docId => {
        const payload = { documentID: docId, fileExtensions: ['pdf', 'tif', 'tiff'] }
        promiseArr.push(this.$store.dispatch('document/checkFileExtension', payload))
      })
      const promises = await Promise.all(promiseArr)

      this.checkedDocumentsAreTiffOrPDF = !promises.includes('False')
    },
    async updateFieldsSelectedDocuments() {
      this.showActions = false
      if (this.checkedDocuments.length < 1) {
        return;
      }
      const fileData = await this.$store.dispatch('document/getDocumentDetails', { documentID: this.checkedDocuments[0] })
      this.selectedFormID = fileData.FormId
      this.showUpdateFieldsModal = true
    },    
    updateOcrAllPages(value) { this.ocrAllPages = value },
    async updateWorkflowActions(items)
    {
      const canRejectIndex = items ? items.Columns.Values.indexOf('CanMassReject') : 4
      this.rejectableDocuments = items ? items.Rows.Values.filter(pendingDocument => pendingDocument.Values[canRejectIndex] === 'True').map(doc => doc.Values[0]) : []
      const canReassignIndex = items ? items.Columns.Values.indexOf('CanMassReassign') : 3
      this.reassignableDocuments = items ? items.Rows.Values.filter(pendingDocument => pendingDocument.Values[canReassignIndex] === 'True').map(doc => doc.Values[0]) : []
      const canAccessIndex = items ? items.Columns.Values.indexOf('__CanAccess') : 15
      this.inaccessibleDocuments = await items ? items.Rows.Values.filter(pendingDocument => pendingDocument.Values[canAccessIndex] === '0').map(doc => doc.Values[0]) : []
      const canApproveIndex = items ? items.Columns.Values.indexOf('CanMassApprove') : 2
      this.approvableDocuments = items ? items.Rows.Values.filter(pendingDocument => pendingDocument.Values[canApproveIndex] === 'True').map(doc => doc.Values[0]) : []
    },
    workflowSelected(workflowID) {
      this.selectedWorkflowID = workflowID

      if (this.selectedWorkflowID <= 0) {
        this.showStartWorkflow = false
        return
      }

      this.tryStartWorkflow()
    },
    workflowSelectUserOKClick(payload) {
      if (!payload || !payload.username || !payload.value) {
        this.showStartWorkflow = false
        return
      }
      this.userAssignPayload.documentIDList = this.checkedDocuments.filter(a => a)
      this.userAssignPayload.promptData.prmt_ChooseFromGroup.cfg_UserID = payload.value
      this.tryStartWorkflow()
    },
    async workspacePageViewed(requestedPage = null, numItems = null) {
      if (!requestedPage || (!numItems && typeof numItems !== 'number')) return
      let page = '' 
      if (requestedPage === 'my-tasks-pending') page = 'Tasks-Pending'
      else if(requestedPage === 'my-tasks-completed') page = 'Tasks-Completed'
      else if(requestedPage === 'my-requests-drafts') page = 'Requests-Drafts'
      else if(requestedPage === 'my-requests-pending') page = 'Requests-Pending'
      else if(requestedPage === 'my-requests-completed') page = 'Requests-Completed'
      else if(requestedPage === 'my-requests-recent-documents') page = 'Recent Documents'
      if (page === '') return

      await this.$amplitude.trackEvent('Workspace Page Viewed', {
        'page': page,
        'numItems': numItems,
      })
    },
    yesConfirmationClick() {
      this.showConfirmationDialog = false

      switch (this.confirmationType) {
        case 'Delete':
        case 'Recycle':
          this.deleteSelectedDocuments(false)
          break;
        case 'LegalHold':
          this.legalHoldSelectedDocuments()
          break;
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .email-modal-div {
    overflow: auto;
  }

  .action-button {
    margin-top: 10px;
  }

  .vasion-loading-indicator {
    z-index: 4;
  }

  .control-box{
    display: flex;
    align-items: center;
    z-index: 3;
  }
  #home-inbox-actions-button {
    position: relative;
    right: 3px;
    z-index: 3;
  }
  .header-text {
    padding-top: 10px;
    margin-bottom: 10px;
  }
  .inboxGridDiv {
    width: 100%;
    height: calc(100vh - 274px);
    overflow: auto;
  }

  .main-div {
    width: 100%;
    background-color: $white;
  }

  .table-controls{
    display: flex;
  }
  .table-nav{
    display: flex;
    flex-wrap: wrap;
  }

  #filterListIconDiv {
    margin-left: auto;
  }

  .backgroundGrey {
    background: $grey-100;
  }
  @media(max-width: $tablet){
    .table-nav{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
</style>
